import React from 'react'
import ImageUrl from '../images/logo_white.png'

const Footer = () => {
  return (
	<div className="ui inverted vertical footer segment" >
		<div className="ui center aligned container">
			<img src={ImageUrl} className="ui centered tiny image"/>
		</div>
	</div>
  )
}

export default Footer