import React, { useEffect } from 'react'
import { useState } from 'react';
import api from '../../services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Logs = ({dateTimeFilter , setDateTimeFilter, dataToDisplay,setDataToDisplay, isLoading}) => {

  const [startDate, setStartDate] = useState(dateTimeFilter.startDate);
  const [startHour, setStartHour] = useState(dateTimeFilter.startHour);
  const [endDate, setEndDate] = useState(dateTimeFilter.endDate);
  const [endHour, setEndHour] = useState(dateTimeFilter.endHour);
  const [tag, setTag] = useState("");

  const [message, setMessage] = useState("");
  const [isClear, setIsClear] = useState(false);

  useEffect(() => {
    if(message !== ""){
      console.log(message);
      setMessage(message+dataToDisplay);
    }else{
      if (typeof dataToDisplay === 'string' ) {
        setMessage(dataToDisplay);
      }
    }
    
  }, [dataToDisplay])
  
  useEffect(() => {
    if(isClear){
      setMessage("");
      setDataToDisplay("")
    }
    setIsClear(false);
  
}, [isClear])



const searchFunction = ()=>{
  if(((dateTimeFilter.startDate && dateTimeFilter.startHour) ||
    (dateTimeFilter.endDate && dateTimeFilter.endHour) || tag !== ""
  )){
    if(dateTimeFilter.msisdn !== ""){
      // console.log('backend/sms?msisdn='+dateTimeFilter.msisdn+"&startDate="+dateTimeFilter.startDate+" "+dateTimeFilter.startHour+"&endDate="+dateTimeFilter.endDate+" "+dateTimeFilter.endHour);
      // date de fin optionnel
      let url = 'backend/sms?msisdn='+dateTimeFilter.msisdn+"&startDate="+dateTimeFilter.startDate+" "+dateTimeFilter.startHour;
      if(endDate !== ""){
        url += "&endDate="+dateTimeFilter.endDate+" "+dateTimeFilter.endHour;
      }
      url += "&sessionName="+tag
      console.log(url);
      api(url,'GET').then((response)=>{
      
      let val = ""
      if( response && response.inboundMessages){
        const data = response.inboundMessages;
        data.forEach(msg => {
          if(msg.raw_sms['outboundSMSMessageRequest']){
            val += msg.sms_type+" : "+msg.raw_sms.outboundSMSMessageRequest.message+"\n";
          }
          if(msg.raw_sms['inboundSMSMessageNotification']){
            val += msg.sms_type+" : "+msg.raw_sms.inboundSMSMessageNotification.inboundSMSMessage.message+"\n";
          }
        });
        // console.log(val);
      }
      setDataToDisplay(val);
      })

    }else{
      toast.error('Error : MSISDN is missing', {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }  
  }else{
    toast.error('Error : Set at least start DATE and HOUR to search ', {
      position: "top-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      });
  }
}


  return (
    <div className="ui grid" style={(isLoading) ? {filter: "blur(2px)"} : {}}>
      <div className="sixteen wide column centered segment">
        <textarea id="logsArea" value={message} onChange={e=>{}} disabled={isLoading} ></textarea>
        
      <button className="ui button" id="clearButton" type="submit" onClick={()=>setIsClear(true)} style={{marginTop:"15px"}} disabled={isLoading}>
        Clear
      </button> 
      <div className='filter'>
            <div className=''>
              <label htmlFor="start-date">Start Date:</label>
              <input
                type="date"
                id="start-date"
                value={startDate}
                disabled={isLoading}
                onChange={(e) => {
                  dateTimeFilter.startDate = e.target.value
                  setDateTimeFilter(dateTimeFilter)
                  setStartDate(e.target.value)
                }}
                />
            </div>

            <div>
              <label htmlFor="start-hour">Start Hour:</label>
              <input
                type="time"
                id="start-hour"
                value={startHour}
                disabled={isLoading}
                onChange={(e) => {
                  dateTimeFilter.startHour = e.target.value
                  setDateTimeFilter(dateTimeFilter)
                  setStartHour(e.target.value)
                }}
                />

            </div>
            <div>
              <label htmlFor="end-date">End Date:</label>
              <input
                type="date"
                id="end-date"
                value={endDate}
                disabled={isLoading}
                onChange={(e) => {
                  console.log(typeof e.target.value);
                  dateTimeFilter.endDate = e.target.value
                  console.log(dateTimeFilter.endDate);
                  setDateTimeFilter(dateTimeFilter)
                  setEndDate(e.target.value)
                }}
                />

            </div>

            <div>
              <label htmlFor="end-hour">End Hour:</label>
              <input
                type="time"
                id="end-hour"
                value={endHour}
                disabled={isLoading}
                onChange={(e) => {
                  dateTimeFilter.endHour = e.target.value
                  setDateTimeFilter(dateTimeFilter)
                  setEndHour(e.target.value)
                }}
              />
            </div>
            <div>
              <label htmlFor="end-hour">Tag</label>
              <input
                type="text"
                id="tag-name"
                placeholder='Search by tag'
                value={tag}
                disabled={isLoading}
                onChange={(e) => {
                  // dateTimeFilter.tag = e.target.value
                  // setDateTimeFilter(dateTimeFilter)
                  setTag(e.target.value)
                }}
              />
            </div>
            <button className="ui button" id="searchButton" type="submit" onClick={searchFunction} disabled={isLoading} >
              Search
            </button> 
          </div>
      </div>
    </div>
  )
}

export default Logs