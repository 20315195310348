import React, { useState } from 'react'

const Card = ({dataLoader, data,country,dialogResponse,setDialogResponse,dateTimeFilter,setDateTimeFilter}) => {

  const [counterValue, setCounterValue] = useState((data.counter ? data.counter : false));
  const [isChecked, setIsChecked] = useState(data.SimAtlasUpdateCNTR);
  const [iccidValue, setIccidValue] = useState(data.card.iccid);
  const [msisdnValue, setMsisdnValue] = useState(data.card.msisdn);
  const [kicKeyValue, setKicKeyValue] = useState(data.card.kic_key)
  const [kidKeyValue, setKidKeyValue] = useState(data.card.kid_key)
  const [kicValue, setKicValue] = useState(data.applet.kic)
  const [kidValue, setKidValue] = useState(data.applet.kid)



  const phoneFormat = (input) => {
    input = input.replace(/\D/g,'');
  
    input = input.substring(0,40);
  
    var size = input.length;
    if(size == 0){
      input = input;
    }else if(size < 3){
      input = '+'+input;
    }else{
      input = '+'+input.substring(0,2)+' '+input.substring(2,40)
    }
    return input; 
  }

  return (
    <div className="ui grid">
      <div className=" four wide column centered segment">
        {/* <br/> */}
        <div className="field">
        <label>Counter </label>
        <input type="text" id="force_counter" name="force_counter" tabIndex={2} placeholder="Force a counter here" maxLength={10}
          value={counterValue?counterValue:""}
          onChange={(event)=>{
            let { value } = event.target;
            setCounterValue(value)
            data.counter = value.padStart(10, '0');
            dataLoader(data)
            setDialogResponse("")
            }}
            autoFocus={dialogResponse == 'rejected' ? true :false }
        />

        </div>
        {/* <div className="ui test toggle checkbox" >
          <input type="checkbox" id="update_simatlas_counter" name="update_simatlas_counter" 
          checked={isChecked}
          onChange={() => {
            // setIsChecked(!isChecked);
            // data.SimAtlasUpdateCNTR = !isChecked;
            // dataLoader(data)
            }}
          tabIndex={1}
          />
          <label>Update the forced counter in SimAtlas  <small>(disabled )</small></label>
          
        </div> */}
        
        <form className="ui form">
          <div className="field">
            <label>ICCID</label>
            <input type="text" id="iccid" name="iccid" data-content="ICCID de la carte" placeholder="89XXXXXXXXXXXXXXXXX" 
              value={iccidValue}
              onChange={(event)=>{
                const { value } = event.target;
                setIccidValue(value)
                data.card.iccid = value;
                dataLoader(data)
                }}
            />
          </div>
        </form>
      </div>

      <div className="eight wide column centered segment">
        <form className="ui form">
          <div className="field">
            <label>MSISDN<span className="mandatory">*</span></label>
            <input id="msisdn" type="tel" className="tel" pattern="\d*" x-autocompletetype="tel" data-content="MSISDN sous la forme +XX XXXXXXXXX" placeholder="+XX YYYYYYYYY" 
              value={msisdnValue}
              onChange={(event)=>{
                const { value } = event.target;
                const newVal = phoneFormat(value);

                setMsisdnValue(newVal)
                data.card.msisdn = newVal.replace(/\s/g, '');

                const filter = dateTimeFilter
                filter.msisdn = newVal.replace(/\s/g, '')
                setDateTimeFilter(filter);

                dataLoader(data)
                }}
            />
          </div>

          <div className="field">
            <label>KIC Key<span className="mandatory">*</span></label>
            <input type="text" id="kic_key" name="kic_key" data-content="Clef KIC" placeholder="XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX" 
              value={kicKeyValue}
              onChange={(event)=>{
                const { value } = event.target;
                setKicKeyValue(value)
                data.card.kic_key = value;
                dataLoader(data)
                }}
            />
          </div>

          <div className="field">
            <label>KID Key<span className="mandatory">*</span></label>
            <input type="text" id="kid_key" name="kid_key" data-content="Clef KID" placeholder="XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX" 
              value={kidKeyValue}
              onChange={(event)=>{
                const { value } = event.target;
                setKidKeyValue(value)
                data.card.kid_key = value;
                dataLoader(data)
                }}
            />
          </div>
        </form>
      </div>

      <div className="four wide column centered segment">
        <form className="ui form">
          <div className="field">
            <label>Country</label>
            <input type="text" id="country" name="country" value={country} disabled style={{opacity:"0.45"}}
              // value={kicValue}
              // onChange={(event)=>{
              //   const { value } = event.target;
              //   setKicValue(value)
              //   data.counter = value;
              //   dataLoader(data)
              //   }}
            />
          </div>

          <div className="field">
            <label>KIC<span className="mandatory">*</span></label>
            <input type="text" id="kic" name="kic" data-content="KIC sous la forme XY où X est le n° de Keyset et Y l'algorithme de chiffrement" placeholder="XX" 
              value={kicValue}
              onChange={(event)=>{
                const { value } = event.target;
                setKicValue(value)
                data.applet.kic = value;
                dataLoader(data)
                }}
            />
          </div>

          <div className="field">
            <label>KID<span className="mandatory">*</span></label>
            <input type="text" id="kid" name="kid" data-content="KID sous la forme XY où X est le n° de Keyset et Y l'algorithme de chiffrement" placeholder="XX" 
              value={kidValue}
              onChange={(event)=>{
                const { value } = event.target;
                setKidValue(value)
                data.applet.kid = value;
                dataLoader(data)
                }}
            />
          </div>

        </form>

      </div>
    </div>
  )
}

export default Card