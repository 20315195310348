//this file is just for testing purposes

const BASE_URL = 'https://5g.secure-element.orange.com/iota/api'; 

// Function to handle API requests
const api = async (endpoint, method = 'GET', data = null) => {
  const requestOptions = {
    method,
    cache: 'no-store',
    headers: {
      'Content-Type': 'application/json',
    },
    dataType: 'json',
    // crossDomain: true,
  };
  if (data) {
    requestOptions.body = JSON.stringify(data);
  }
  try {
    console.info('Before fetch');
    const response = await fetch(`${BASE_URL}/${endpoint}`, requestOptions);
    console.info('After fetch');
    console.info(response);
    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData.status || 'Something went wrong');
    }
    return responseData;
  } catch (error) {
    console.error('API Error:', error.message);
    return error;
  }
};

export default api;
