import React, { useState } from 'react';

function CustomDialog({showDialog,setShowDialog,setDialogResponse}) {
  

  const handleConfirm = () => {
    setShowDialog(false);
    setDialogResponse("confirmed")
  };

  const handleCancel = () => {
    setShowDialog(false);
    setDialogResponse("rejected")
  };

  const timestamp = Date.now();
  const hexTimestamp = timestamp.toString(16).toUpperCase();
  return (
    <div>
      {showDialog && (
        <div className="dialog ui button "
         style={{
            position: 'fixed',
            top: "50%",
            left: "50%",
            zIndex:"999999",
            transform: "translate(-50%, -180%)",
            backgroundColor: "rgba(224,225,226,.8)",
            padding: "20px",
            borderRadius: "4px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)"
            
          }}
          >
          <h2 style={{
            fontFamily:"'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif",
            fontWeight:'normal',
            fontSize:"1.5rem"
            }}>Counter value is missing, timestamp value (> {hexTimestamp}) will be used for the counter,do you confirm?</h2>
          <button onClick={handleConfirm} className="ui button">Yes</button>
          <button onClick={handleCancel} className="ui button" >No</button>
        </div>
      )}
    </div>
  );
}

export default CustomDialog;
