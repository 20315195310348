import React, { useEffect, useState } from 'react'

const Spi1 = ({dataLoader, data,setSpi1}) => {

let binary = parseInt(data.spi1, 16).toString(2).padStart(5, '0');

const [counter, setcounter] = useState(binary[0]+binary[1]);
const [cipher, setcipher] = useState((binary[2] === "1" ? true : false));
const [rcccds, setRcccds] = useState(binary[3]+binary[4]);

useEffect(() => {

	let ciphering = (cipher ? '1' : '0');
	let tmp = "000" + counter + ciphering + rcccds;

	const decimal = parseInt(tmp, 2);
  	const hex = decimal.toString(16).toUpperCase().padStart(2, '0');
	data.spi1 = hex
	setSpi1(hex)
	dataLoader(data)

}, [counter,cipher,rcccds])

useEffect(()=>{
	setcounter(binary[0]+binary[1]);
	setcipher((binary[2] === "1" ? true : false));
	setRcccds(binary[3]+binary[4]);
},[data.spi1])

  return (
		<div className="ui grid">
			<div className="five wide column centered segment">

				<div className="ui slider checkbox">
					<input type="radio" id="spi1_nocnt" name="spi1_counter" checked={counter === "00"&& ("checked")}
						onChange={()=>{
							setcounter("00");
						}} />
					<label>No counter</label>
				</div><br/><br/>

				<div className="ui slider checkbox">
					<input type="radio" id="spi1_cnt" name="spi1_counter"  checked={counter === "01"&& ("checked")}
						onChange={()=>{
							setcounter("01");
						}} />
					<label>Counter available</label>
				</div><br/>

				<div className="ui slider checkbox">
					<input type="radio" id="spi1_cnthigh" name="spi1_counter"  checked={counter === "10"&& ("checked")}
						onChange={()=>{
							setcounter("10");
						}} />
					<label>Counter Higher</label>
				</div><br/>

				<div className="ui slider checkbox">
					<input type="radio" id="spi1_cnt1" name="spi1_counter"  checked={counter === "11"&& ("checked")}
						onChange={()=>{
							setcounter("11");
						}} />
					<label>Counter = +1</label>
				</div><br/>
			</div>

			<div className="four wide column centered segment">
				<div className="ui test toggle checkbox">
					<input type="checkbox" id="spi1_ciphering" name="spi1_ciphering" tabIndex={0} checked={cipher}
						onChange={()=>{
							setcipher(!cipher);
						}} />
					<label>Ciphering</label>
				</div>
			</div>

			<div className="five wide column centered segment">
				<div className="ui slider checkbox">
					<input type="radio" id="spi1_norcccds" name="spi1_rcccds"   checked={rcccds === "00"&& ("checked")}
						onChange={()=>{
							setRcccds("00");
						}} />
					<label>No RC/CC/DS</label>
				</div><br/><br/>
				<div className="ui slider checkbox">
					<input type="radio" id="spi1_rc" name="spi1_rcccds"  checked={rcccds === "01"&& ("checked")}
						onChange={()=>{
							setRcccds("01");
						}} />
					<label>RC</label>
				</div><br/>
				<div className="ui slider checkbox">
					<input type="radio" id="spi1_cc" name="spi1_rcccds"  checked={rcccds === "10"&& ("checked")}
						onChange={()=>{
							setRcccds("10");
						}} />
					<label>CC</label>
				</div><br/>
				<div className="ui slider checkbox">
					<input type="radio" id="spi1_ds" name="spi1_rcccds"  checked={rcccds === "11"&& ("checked")}
						onChange={()=>{
							setRcccds("11");
						}} />
					<label>DS</label>
				</div>
			</div>
		</div>
  )
}

export default Spi1