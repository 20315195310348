import React, { useState } from 'react'
import Applet from './Tabs/Applet'
import Carte from './Tabs/Card'
import Spi1 from './Tabs/Spi1'
import Spi2 from './Tabs/Spi2'
import Logs from './Tabs/Logs'
import api from '../services/Api'
import CustomDialog from './CustomDialog'
import { useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AllTabs = () => {
  let data ={
    "applet": {
      "appletName": "",
      "tar": "",
      "kic": "",
      "kid": ""
    },
  
    "appletCommand": {
      "commandName": "None",
      "command": ""
    },
  
    "card": {
      "iccid": "",
      "msisdn": "",
      "kic_key": "",
      "kid_key": ""
    },
    "spi1": "12",
    "spi2": "21",
    "counter" : false
  }
  
  const [sessionName, setSessionName] = useState("");

  const currentDate = new Date();
  const utcHours = String(currentDate.getHours()).padStart(2, '0');
  const utcMinutes = String(currentDate.getMinutes()).padStart(2, '0');
  const utcSeconds = String(currentDate.getUTCSeconds()).padStart(2, '0');

  const utcDay = String(currentDate.getDate()).padStart(2, '0');
  const utcMonth = String(currentDate.getMonth() + 1).padStart(2, '0');
  const utcYear = currentDate.getFullYear();
  
  const dateTime = {
    "msisdn":"",
    "sessionName":sessionName,
    "startDate":utcYear+"-"+utcMonth+"-"+utcDay,
    "startHour":utcHours+":"+utcMinutes,
    "endDate":'',
    "endHour":'',
  }

  
  const [activeTab, setActiveTab] = useState('applet_tab');
  const [showDialog, setShowDialog] = useState(false);
  const [dialogResponse, setDialogResponse] = useState();
  const [spi1, setSpi1] = useState(data.spi1);
  const [spi2, setSpi2] = useState(data.spi2);
  const [country, setCountry] = useState("FR");
  const [dataToSend, setDataToSend] = useState(data);
  const [isGetLogs, setIsGetLogs] = useState(false);
  const [dataToDisplay, setDataToDisplay] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  
  
  const [dateTimeFilter, setDateTimeFilter] = useState(dateTime);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };


  
  const sleep = ms => new Promise(r => setTimeout(r, ms));

  useEffect(() => {
    console.log('---- after 10secs : '+ dateTimeFilter.msisdn);
    if(dataToSend.card.msisdn !== ""){
      
      console.log('backend/sms?msisdn='+dataToSend.card.msisdn+"&startDate="+dateTimeFilter.startDate+" "+dateTimeFilter.startHour);
      
       api('backend/sms?msisdn='+dataToSend.card.msisdn+"&startDate="+dateTimeFilter.startDate+" "+dateTimeFilter.startHour,'GET').then((response)=>{
        // console.log(JSON.stringify(response));
        let val = ""
        if( response && response.inboundMessages ){
          const data = response.inboundMessages;
          data.forEach(msg => {
            // console.log(msg.extracted_data_deciphered);
            // let outboundMessage = JSON.parse(msg.raw_sms)
            console.log(msg);
            if(msg.raw_sms['outboundSMSMessageRequest']){
              val += msg.sms_type+" : "+msg.raw_sms.outboundSMSMessageRequest.message+"\n";
            }
            if(msg.raw_sms['inboundSMSMessageNotification']){
              val += msg.sms_type+" : "+msg.raw_sms.inboundSMSMessageNotification.inboundSMSMessage.message+"\n";
            }
          });
        }else{
          console.log(response);// add notif here !
        }
        setDataToDisplay(val);
       })
      //  setDataToDisplay("");
    }
  }, [isGetLogs])

  const send = () => {
    console.log(dataToSend);
    if(dataToSend.card.msisdn !== ""){
      setActiveTab('logs_tab')
      setIsLoading(true)
      api('backend/sms?commandType=binary&addUdh=true&sessionName='+sessionName,'POST',dataToSend).then(async (response)=>{
          // console.log(error);
        if (response.status === "created") {
          const requestTime = new Date();
          const utcHours = String(requestTime.getHours()).padStart(2, '0');
          const utcMinutes = String(currentDate.getMinutes()).padStart(2, '0');
          dateTimeFilter.startHour = utcHours+":"+utcMinutes;
          console.log(dateTimeFilter.startHour);
          if(response && response.counter){
            console.log(response);
            dataToSend.counter = response.counter.info["updated counter"];
          }else{
            dataToSend.counter = false
          }
    
          setDataToSend(dataToSend);
          console.log('---- Attend 10secs');
          toast.success('Command sent successfully! ', {
            position: "top-left",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
          await sleep(10000);
          setIsLoading(false)
          //sleep 10secs and get first POR 
          setIsGetLogs(!isGetLogs);
          
        }else{
          setIsLoading(false)
          console.log(response);
          toast.error('Error : Make sure you put a valid MSISDN', {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
        }
      })
    }else{
      console.log(dataToSend)
      setActiveTab('card_tab')
      toast.error('Error : MSISDN is missing', {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
    
  }

  const sendRequest = ()=>{
    console.log(dialogResponse);
    setDialogResponse("")
    if(!dataToSend.counter){
      setShowDialog(true) 
    }else{
      send()
      //GET LOGS HERE OR IN SEND()
    }
    
  }

  

  useEffect(() => {
    if(dialogResponse === "confirmed" ){
      send()
    }

    if( dialogResponse === "rejected"){
      setActiveTab('card_tab')
    }
    
  }, [dialogResponse])
  
  
  const [editing, setEditing] = useState(false);
  return (
    <div style={{height:"80vH"}}>
      { isLoading &&  
      <div className="loader" style={{position:'sticky',top:'48vH',zIndex:'99999'}}></div>
        }
      <ToastContainer />
      <div action="#" className="ui form" method="POST">
          <CustomDialog showDialog = {showDialog} setShowDialog={setShowDialog} setDialogResponse={setDialogResponse} />
          
          <div className="ui main text basic segment" id='overlay' style={(showDialog) ? {filter: "blur(2px)"} : {}}>
              <div className="header item" style={{display:"flex",justifyContent:"space-between"}}>
                <p>Service d'envoi de commandes cartes SIM OTA</p>
              </div>
              <div className="ui top attached tabular menu">
                <a 
                  className={activeTab === 'applet_tab' ? ' item active' : 'item'}
                  onClick={() =>{
                    if(!showDialog && !isLoading){
                      handleTabChange('applet_tab')}
                    }
                  } 
                  id="carte" 
                  data-tab="applet_tab">Applet</a>
                <a 
                  className={activeTab === 'card_tab' ? ' item active' : 'item'} 
                  onClick={() =>{
                    if(!showDialog && !isLoading){
                      handleTabChange('card_tab')}
                    }
                  } 
                  id="carte" 
                  data-tab="card_tab">Carte</a>
                {(editing && activeTab === 'spi1_tab')? (
                  <input
                    type="text"
                    value={spi1}
                    onChange={(event)=>setSpi1(event.target.value)}
                    onBlur={()=>{
                        dataToSend.spi1 = spi1;
                        setDataToSend(dataToSend);
                        setEditing(false)
                      }}
                    style={{maxWidth:"95px"}}
                    autoFocus
                  />
                  ):(  
                  <a 
                    className={activeTab === 'spi1_tab' ? ' item active' : 'item'} 
                    onClick={() =>{
                      if(!showDialog && !isLoading){
                        handleTabChange('spi1_tab')}
                      }
                    } 
                    onDoubleClick={()=>{setEditing(true);console.log(dataToSend.spi1);}}
                    id="spi1" 
                    data-tab="spi1_tab">SPI1={spi1}</a>
                  )
                }

                {(editing && activeTab === 'spi2_tab')? (
                  <input
                    type="text"
                    value={spi2}
                    onChange={(event)=>setSpi2(event.target.value)}
                    onBlur={()=>{
                        dataToSend.spi2 = spi2;
                        setDataToSend(dataToSend);
                        setEditing(false)
                      }}
                    style={{maxWidth:"95px"}}
                    autoFocus
                  />
                  ):(
                    <a 
                      className={activeTab === 'spi2_tab' ? ' item active' : 'item'} 
                      onClick={() =>{
                        if(!showDialog && !isLoading){
                          handleTabChange('spi2_tab')}
                        }
                      } 
                      onDoubleClick={()=>setEditing(true)}
                      id="spi2" 
                      data-tab="spi2_tab"
                      >
                        SPI2 = {spi2} 
                      </a>
                  )
                }
                <a 
                  className={activeTab === 'logs_tab' ? ' item active' : 'item'}
                  onClick={() =>{
                    if(!showDialog && !isLoading){
                      handleTabChange('logs_tab')
                    }
                  }
                }
                  id="logs" 
                  data-tab="logs_tab">Logs</a>
              </div>

              {activeTab === 'applet_tab' && (
                <div className="ui bottom attached tab segment active" data-tab="applet_tab">
                  <Applet dataLoader = {setDataToSend} data={dataToSend} setSessionName={setSessionName} sessionName={sessionName}/>
                </div>
              )}

              {activeTab === 'card_tab' && (
                <div className="ui bottom attached tab segment active" data-tab="card_tab">
                  <Carte dataLoader = {setDataToSend} data={dataToSend} country={country} dialogResponse={dialogResponse} setDialogResponse={setDialogResponse} 
                    setDateTimeFilter={setDateTimeFilter} dateTimeFilter={dateTimeFilter}
                  />
                </div>
              )}
              
              {activeTab === 'spi1_tab' && (
              <div className="ui bottom attached tab segment active" data-tab="spi1_tab">
                <Spi1 dataLoader = {setDataToSend} data={dataToSend} setSpi1={setSpi1} />
              </div>
              )}
              {activeTab === 'spi2_tab' && (
              <div className="ui bottom attached tab segment active" data-tab="spi2_tab">
                <Spi2 dataLoader = {setDataToSend}  data={dataToSend} setSpi2={setSpi2} />
              </div>
              )}
              {activeTab === 'logs_tab' && (
              <div className="ui bottom attached tab segment active" data-tab="logs_tab">
                <Logs dateTimeFilter={dateTimeFilter} setDateTimeFilter={setDateTimeFilter} dataToDisplay={dataToDisplay} setDataToDisplay={setDataToDisplay} isLoading={isLoading} />
              </div>
              )}
          {/* onClick="return send();" */}
          {/* Condition d'activation du bouton : tout les champs dataToSend doivent être remplies */}
            <button className="ui button" id="sendButton" type="submit" onClick={sendRequest}  disabled={isLoading }>
              <i className="play icon"></i> Envoyer le SMS
            </button> 
          </div>
      </div>
    </div>
  )
}



export default AllTabs