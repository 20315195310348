import React from 'react'
import ImageUrl from '../images/logo_black.png'

const Header = () => {
  return (
	<div className="ui borderless main menu" style={{height:"10vH"}} >
		<div className="ui text ">
			<div className="header item">
				<img className="ui image tiny" src={ImageUrl}/>
			</div>
		</div>
	</div>
  )
}

export default Header