import React, { useEffect, useState } from 'react'

const Spi2 = ({dataLoader, data,setSpi2}) => {
let binary = parseInt(data.spi2, 16).toString(2).padStart(6, '0');

const [por, setpor] = useState(binary[2]+binary[3]);
const [cipher, setcipher] = useState(binary[1] === "1");
const [smsDeliver, setsmsDeliver] = useState(binary[0] === "1");
const [porOn, setporOn] = useState(binary[4]+binary[5]);

useEffect(() => {  
	let ciphering = (cipher ? '1' : '0');
  let sms = (smsDeliver ? '1' : '0');
	var tmp = "00" + sms + ciphering + por + porOn;
  const decimal = parseInt(tmp, 2);
  const hex = decimal.toString(16).toUpperCase().padStart(2, '0');
 	data.spi2 = hex
	setSpi2(hex)
	dataLoader(data)
}, [por,cipher,porOn,smsDeliver])

useEffect(()=>{
  setpor(binary[2]+binary[3]);
	setcipher(binary[1] === "1");
	setsmsDeliver(binary[0] === "1");
	setporOn(binary[4]+binary[5]);  
},[data.spi2])

  return (
    <div className="ui grid">
      <div className="five wide column centered segment">
        <div className="ui slider checkbox">
          <input type="radio" id="spi2_noPorRcccds" name="spi2_porRcccds" checked={por === "00"&& ("checked")}
						onChange={()=>{
							setpor("00");
						}} />
          <label>No PoR RC/CC/DS</label>
        </div><br/><br/>
        <div className="ui slider checkbox">
          <input type="radio" id="spi2_porRc" name="spi2_porRcccds" checked={por === "01"&& ("checked")}
						onChange={()=>{
							setpor("01");
						}} />
          <label>PoR RC</label>
        </div><br/>
        <div className="ui slider checkbox">
          <input type="radio" id="spi2_porCc" name="spi2_porRcccds" checked={por === "10"&& ("checked")}
						onChange={()=>{
							setpor("10");
						}} />
          <label>PoR CC</label>
        </div><br/>
        <div className="ui slider checkbox">
          <input type="radio" id="spi2_porDs" name="spi2_porRcccds" checked={por === "11"&& ("checked")}
						onChange={()=>{
							setpor("11");
						}} />
          <label>PoR DS</label>
        </div>
      </div>

      <div className="five wide column centered segment">
        <div className="ui test toggle checkbox">
          <input type="checkbox" id="spi2_porCiphering" name="spi2_porCiphering" tabIndex={0} checked={cipher}
						onChange={()=>{
							setcipher(!cipher);
						}} />
          <label>Ciphering</label>
        </div><br/><br/>

        <div className="ui slider checkbox">
          <input type="radio" id="spi2_deliver_report" name="spi2_report" checked={!smsDeliver&& ("checked")}
						onChange={()=>{
							setsmsDeliver(!smsDeliver);
						}} />
          <label>Deliver Report</label>
        </div><br/>
        <div className="ui slider checkbox">
          <input type="radio" id="spi2_sms_submit" name="spi2_report" checked={smsDeliver && ("checked")}
						onChange={()=>{
							setsmsDeliver(!smsDeliver);
						}} />
          <label>SMS Submit</label>
        </div><br/>
      </div>

      <div className="five wide column centered segment">
        <div className="ui slider checkbox">
          <input type="radio" id="spi2_noPor" name="spi2_por" checked={porOn === "00"&& ("checked")}
						onChange={()=>{
							setporOn("00");
						}} />
          <label>No PoR</label>
        </div><br/><br/>
        <div className="ui slider checkbox">
          <input type="radio" id="spi2_porAlways" name="spi2_por" checked={porOn === "01"&& ("checked")}
						onChange={()=>{
							setporOn("01");
						}} />
          <label>Always PoR</label>
        </div><br/>
        <div className="ui slider checkbox">
          <input type="radio" id="spi2_porOnError" name="spi2_por" checked={porOn === "10"&& ("checked")}
						onChange={()=>{
							setporOn("10");
						}} />
          <label>PoR on error</label>
        </div>
      </div>
    </div>
  )
}

export default Spi2
