import Footer from './components/Footer';
import Header from './components/Header';
import Tabs from './components/AllTabs';
import { useState } from 'react';

function App() {
  const [sessionName, setSessionName] = useState("");
  const [isEntered, setIsEntered] = useState(false);

  
  
  return (
    <div className="App" style={{display:"flex",height:"100vH",flexDirection:"column",justifyContent:"space-between"}}>
      <Header/>
      <Tabs />
      <Footer/>    
    </div>
  );
}

export default App;
