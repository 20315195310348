import React, { useEffect, useState } from 'react'
import api from '../../services/Api';

import appletsData from "../../data/applet.json";

const Mustache = require('mustache');

const Applet = ({dataLoader, data,sessionName,setSessionName}) => {

  const [selectedAppletName, setSelectedAppletName] = useState(data.applet.appletName);
  const [selectedTar, setSelectedTar] = useState(data.applet.tar);
  const [selectedAppletCommand, setSelectedAppletCommand] = useState(data.appletCommand.commandName);
  const [selectedCommand, setSelectedCommand] = useState(data.appletCommand.command);

  const [appletNames, setAppletNames] = useState(false);
  const [appletCommands, setAppletCommands] = useState(false);
  const [shortCode, setShortCode] = useState("");


  // Swapping des caractères d'une chaine deux à deux avec padding à F, ex. "20298" => "0292F8"
  const  swapAndPad = (str) => {

    let ret = {"sc":"", "sz": str.length};
  
    // D'abord, on padde si la longueur de la chaîne n'est pas paire
    if(str.length % 2 != 0) {
      
      str += "F";
    }
    
    // Enfin, on swappe les caractères
    for(let idx = 0; idx < str.length - 1; idx += 2) {
      ret.sc += str[idx + 1] + str[idx];
    }
    
    return ret;
  }

  // Conversion d'un entier en Hexa sur deux caractères, ex. 10 => "0A"
  const toHex = (d) => {
    return  ("0" + (Number(d).toString(16))).slice(-2).toUpperCase();
  }
  

  useEffect(() => {
    let appletNames = [];
    Object.entries(appletsData.applet).forEach(([key, value]) => {
      appletNames = [...appletNames,value.name]
    });

    setAppletNames(appletNames);

    //Decommente this if you want to use SimAtlas data
    /*
    api('backend/applets','GET').then((data)=>{
      console.log(data.data);
      if (data.data.length > 0) {
        setAppletNames(data.data)
      }else {
        console.log("Applets array is empty");
      }
    })
    
    api('backend/shortcode?cc=+33','GET').then((data)=>{
      setShortCode(data.shortcode);
    })
    */

  }, [])

  useEffect(()=>{
    if(appletsData.applet[selectedAppletName]) {
      let tar = appletsData.applet[selectedAppletName].tar
      data.applet.tar = tar;
      setSelectedTar(tar)
      dataLoader(data)

      //Get commands list
      let commands = [];
      Object.entries(appletsData.applet[selectedAppletName].command).forEach(([key, value]) => {
        commands = [...commands,Object.keys(value)];
        console.log(value);
      }); 
      setAppletCommands(commands)
    }else{
      // setSelectedTar("")
      setAppletCommands([])
    } 

    //Decommente this if you want to use SimAtlas data
    /*
      api('backend/applet?appletName='+selectedAppletName,'GET').then((tarData)=>{
        if(tarData.data.tar){
          setSelectedTar(tarData.data.tar)
          data.applet.tar = tarData.data.tar;
          dataLoader(data)
        }
        // else{
        //   setSelectedTar("")
        // }
      })
      
      api('backend/appletCommands?appletName='+selectedAppletName,'GET').then((data)=>{
        if (data.data.length > 0) {
          setAppletCommands(data.data)
          }else {
            setAppletCommands([])
            // console.log("Commands array is empty");
          }
      })
      
    //This code is just to get DATA FROM SIMATLAS  
    const allcommands = {"command":[]}; 
    api('backend/appletCommands?appletName='+selectedAppletName,'GET').then((data)=>{
      if (data.data.length > 0) {
        setAppletCommands(data.data)
        console.log("DEBUT ----------");
        data.data.forEach((cmd)=>{
          const command = {"name":"","apdu":""}; 
          api('backend/appletCommand?appletName='+selectedAppletName+'&commandName='+cmd,'GET').then((commandData)=>{
            // console.log(data.data.legacyName);
            let swappedSc = swapAndPad(shortCode);
            // Attention, actuellement, le shortcode est identique quel que soit la nature du tag, REPORT, ALARM et ERROR
            let shortCodes = { 
              REPORT: [{"SIZE": toHex(swappedSc.sz), "SHORTCODE": swappedSc.sc}],
              ALARM: [{"SIZE": toHex(swappedSc.sz), "SHORTCODE": swappedSc.sc}],
              ERROR: [{"SIZE": toHex(swappedSc.sz), "SHORTCODE": swappedSc.sc}]
            };
            if(commandData.data.command){
              let dummy = Mustache.render(commandData.data.command, shortCodes); // Parsing des paramètres juste pour calculer la taille
              let calculedParamsLength = (dummy.length - 8) / 2; // La taille comprend le retranchement de 4 octets, et tout est double à cause de la représentation Hexa
              
              shortCodes.PARAMS_SZ = toHex(calculedParamsLength); // On assigne la bonne taille au paramètre PARAM_SZ
              
              let wroughtCommand = Mustache.render(commandData.data.command, shortCodes);
              // console.log(wroughtCommand);
              // setSelectedCommand(wroughtCommand);
              command.name=cmd
              command.apdu=wroughtCommand
              //  put the comand here all commands
              // allcommands.command = [...allcommands.command,command]
              console.log(JSON.stringify(command));
            }
            // else{
            //   setSelectedCommand("")
            // }
          })

          console.log(allcommands);
        })
        }else {
          setAppletCommands([])
          // console.log("Commands array is empty");
        }
    })*/

  },[selectedAppletName])

  useEffect(()=>{
      if(appletsData.applet[selectedAppletName]) {
        let commands = appletsData.applet[selectedAppletName].command;
        commands.forEach((value) => {
          if(value[selectedAppletCommand]){
            setSelectedCommand(value[selectedAppletCommand]);
            data.appletCommand.command = value[selectedAppletCommand];
            dataLoader(data)
          }
        });
      }

    
    // api('backend/appletCommand?appletName='+selectedAppletName+'&commandName='+selectedAppletCommand,'GET').then((commandData)=>{
    //   // console.log(data.data.legacyName);
    //   let swappedSc = swapAndPad(shortCode);
    //   // Attention, actuellement, le shortcode est identique quel que soit la nature du tag, REPORT, ALARM et ERROR
    //   let shortCodes = { 
    //     REPORT: [{"SIZE": toHex(swappedSc.sz), "SHORTCODE": swappedSc.sc}],
    //     ALARM: [{"SIZE": toHex(swappedSc.sz), "SHORTCODE": swappedSc.sc}],
    //     ERROR: [{"SIZE": toHex(swappedSc.sz), "SHORTCODE": swappedSc.sc}]
    //   };
    //   if(commandData.data.command){
    //     let dummy = Mustache.render(commandData.data.command, shortCodes); // Parsing des paramètres juste pour calculer la taille
    //     let calculedParamsLength = (dummy.length - 8) / 2; // La taille comprend le retranchement de 4 octets, et tout est double à cause de la représentation Hexa
        
    //     shortCodes.PARAMS_SZ = toHex(calculedParamsLength); // On assigne la bonne taille au paramètre PARAM_SZ
        
    //     let wroughtCommand = Mustache.render(commandData.data.command, shortCodes);
    //     // console.log(wroughtCommand);
    //     setSelectedCommand(wroughtCommand);

    //     data.appletCommand.command = wroughtCommand;
    //     console.log(selectedAppletName,selectedAppletCommand);
    //     dataLoader(data)
    //   }
    //   // else{
    //   //   setSelectedCommand("")
    //   // }
    // })
    
    
  },[selectedAppletCommand])
  
  

  return (
    <div className="ui grid ">

    <div className="sixteen wide column centered segment">
      <form className="ui form">
        <div className="field">
          <label>Applet's name</label>

          <select id="applet_name" name="applet_name" 

            value={selectedAppletName}
            onChange={(event)=>{
              const { value } = event.target;
                data.applet.appletName = value;
                setSelectedAppletName(value);
                dataLoader(data)
              }}
          >
            <option value="SELECT_AN_APPLET">Sélectionner une applet ou saisir le TAR ci-dessous</option>
            <option value="None">Autre applet, saisir le tar</option>
              {appletNames && appletNames.map((applet,index)=>(
                <option key={index} value={applet}>{applet}</option>
              )
              )}
          </select>
        </div>

        <div className="field">
          <label>TAR<span className="mandatory">*</span></label>
          <input type="text" id="applet_tar" name="tar" data-content="TAR de l'applet codé sur 3 octets" placeholder="XXXXXX" pattern="[0-9A-F]" maxLength={6}
            value={selectedTar}
            onChange={(event)=>{
              const { value } = event.target;
                data.applet.tar = value;
                data.applet.appletName = "None";
                setSelectedTar(value)
                setSelectedAppletName("None")
                dataLoader(data)
              }}
          />
        </div>

        <div className="field">
          <label>Command name<span className="mandatory">*</span></label>
          <select id="command_name" name="command_name"
            value={selectedAppletCommand}
            onChange={(event)=>{
                const { value } = event.target;
                if(value === "SELECT_A_COMMAND"){
                  data.appletCommand.commandName = "None"
                }else{
                  data.appletCommand.commandName = value;
                }
                setSelectedAppletCommand(value)
                dataLoader(data)
              }}
          >
            <option value="SELECT_A_COMMAND">Sélectionner une commande ou saisir la commande ci-dessous</option>
            <option value="None">Autre commande, saisir l'APDU</option>
            {
              appletCommands && appletCommands.map((applet,index)=>(
                  <option key={index} value={applet}>{applet}</option>
                )
              )
            }
          </select>
        </div>

        <div className="field">
          <label>APDU</label>
          <input type="text" id="command_apdu" name="apdu" data-content="Commande APDU"
            value={selectedCommand} 
            onChange={(event)=>{
              const { value } = event.target;
                data.appletCommand.command = value;
                data.appletCommand.commandName = "None";
                setSelectedCommand(value)
                setSelectedAppletCommand("None")
                dataLoader(data)
              }}
          />
        </div>
        <div className="field">
          <label>Tag</label>
          <input type="text" id="session_name" name="session_name"  placeholder="Enter a tag name "  style={{maxWidth:"250px"}}
            value={sessionName}
            onChange={(event)=>{
              const { value } = event.target;
              setSessionName(value)
              }}
          />
        </div>
      </form>
    </div>
  </div>
  )
}

export default Applet

